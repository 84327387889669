/*!
 * Swiper
 * use : swiper-buldle.js
 */

document.addEventListener("DOMContentLoaded", () => {

  new Swiper(".top-main-swiper", {
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 0,
    effect: "fade",
    speed: 3000,
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 3500,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  });

  new Swiper(".top-interier-swiper", {
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 0,
    speed: 2000,
    autoplay: {
      delay: 2000,
    },
    pagination: {
      el: ".swiper-pagination",
    },
    breakpoints: {
      769: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });

  new Swiper(".top-interier-nagoya-swiper", {
    loop: false,
    slidesPerView: "auto",
    spaceBetween: 0,
    speed: 2000,
    centeredSlides: true,
    autoplay: {
      delay: 2000,
    },
    pagination: {
      el: ".swiper-pagination",
    },
    breakpoints: {
      769: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });
});
