/*!
 * Inview
 * use : in-view.min.js
 */

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".section").forEach((element) => {
    element.classList.add("inview");
  });
  inView(".inview").on("enter", function (el) {
    el.classList.add("is-inview");
  });
});
